<template>
  <a-drawer
    :destroy-on-close="true"
    :title="isUpdate ? '检查项编辑' : '检查项创建'"
    :visible="visible"
    :width="800"
    @close="closeDrawer"
  >
    <a-form-model
      ref="form"
      :label-col="{
        span: 4,
        style: {
          textAlign: 'left'
        }
      }"
      :model="form"
      :rules="rules"
      :wrapper-col="{
        span: 20
      }"
    >
      <a-form-model-item label="名称" prop="name">
        <a-input v-model="form.name" :max-length="128" :disabled="isUpdate"></a-input>
      </a-form-model-item>
      <a-form-model-item label="描述" prop="description">
        <a-input v-model="form.description" :max-length="128"></a-input>
      </a-form-model-item>
      <a-form-model-item label="优先级" prop="priority">
        <a-select
          v-model="form.priority"
          :options="priorityOptions"
        ></a-select>
      </a-form-model-item>
      <a-form-model-item label="资源类型" prop="source_type">
        <a-select
          v-model="form.source_type"
          :options="sourceTypeOptions"
          :disabled="isUpdate"
        ></a-select>
      </a-form-model-item>
      <a-form-model-item label="获取方式" prop="method">
        <a-select
          v-model="form.method"
          :options="[{title: '监控', value: 'monitor'}]"
          :disabled="isUpdate"
        ></a-select>
      </a-form-model-item>
      <a-form-model-item label="监控项" prop="value" v-if="form.method === 'monitor'">
        <a-input v-model="form.value" :max-length="128" :disabled="isUpdate"></a-input>
      </a-form-model-item>
      <a-form-model-item label="判断条件" prop="condition">
        <a-select
          v-model="form.condition"
          :options="conditionOptions"
          :disabled="isUpdate"
        ></a-select>
      </a-form-model-item>
      <a-form-model-item label="建议值" prop="recommended_value">
        <a-input v-model="form.recommended_value" :max-length="128" :disabled="isUpdate"></a-input>
      </a-form-model-item>
      <a-form-model-item label="建议" prop="advice">
        <a-input v-model="form.advice" :max-length="128"></a-input>
      </a-form-model-item>
      <a-form-model-item
        :wrapper-col="{ span: 20, offset: 4 }"
        style="margin-bottom: 0"
      >
        <a-space size="middle">
          <a-button :loading="loading" type="primary" @click="ok">
            确定
          </a-button>
          <a-button @click="closeDrawer">取消</a-button>
        </a-space>
      </a-form-model-item>
    </a-form-model>
  </a-drawer>
</template>

<script>
import { compareConditions, sourceTypes } from '@/utils/const'
import { createPatrolItem, getPatrolItem, updatePatrolItem } from '@/api/patrol-item.js'

export default {
  name: 'InputDrawer',
  data () {
    return {
      visible: false,
      isUpdate: false,
      loading: false,
      priorityOptions: [
        { title: '高', value: 'high' },
        { title: '中', value: 'medium' },
        { title: '低', value: 'low' }
      ],
      sourceTypeOptions: sourceTypes.map(value => {
        return {
          title: this.$t(`source_type.${value}`),
          value
        }
      }),
      conditionOptions: compareConditions.map(value => {
        return {
          title: this.$t(`compare_condition.${value}`),
          value
        }
      }),
      form: {
        id: '',
        name: '',
        description: '',
        priority: 'low',
        source_type: undefined,
        method: undefined,
        value: '',
        condition: undefined,
        recommended_value: '',
        advice: ''
      },
      rules: {
        name: [
          {
            message: '请输入名称',
            required: true,
            trigger: 'blur'
          }
        ],
        source_type: [
          {
            message: '请选择资源类型',
            required: true,
            trigger: 'change'
          }
        ],
        priority: [
          {
            message: '请选择优先级',
            required: true,
            trigger: 'change'
          }
        ],
        method: [
          {
            message: '请选择获取方式',
            required: true,
            trigger: 'change'
          }
        ],
        condition: [
          {
            message: '请选择判断条件',
            required: true,
            trigger: 'change'
          }
        ],
        value: [
          {
            message: '请输入监控项',
            required: true,
            trigger: 'blur'
          }
        ],
        recommended_value: [{
          message: '请输入建议值',
          required: true,
          trigger: 'blur'
        }]
      }
    }
  },
  methods: {
    show (id) {
      if (id) {
        this.form.id = id
        this.isUpdate = true
        getPatrolItem(id)
          .then(res => {
            const data = res.data
            this.form = {
              id,
              name: data.name,
              description: (data.description && data.description !== 'NULL') ? data.description : '',
              priority: data.priority,
              source_type: data.source_type,
              method: data.method,
              value: data.value,
              condition: data.condition,
              recommended_value: data.recommended_value,
              advice: (data.advice && data.advice !== 'NULL') ? data.advice : ''
            }
          })
          .finally(() => {
            this.visible = true
          })
      } else {
        this.isUpdate = false
        this.visible = true
      }
    },
    closeDrawer () {
      this.form = {
        id: '',
        name: '',
        description: '',
        priority: 'low',
        source_type: undefined,
        method: undefined,
        value: '',
        condition: '',
        recommended_value: '',
        advice: ''
      }
      this.$refs.form.resetFields()
      this.visible = false
    },
    ok () {
      this.$refs.form.validate(valid => {
        if (valid) {
          const params = {
            priority: this.form.priority
          }
          if (this.form.description) {
            params.description = this.form.description
          }
          if (this.form.advice) {
            params.advice = this.form.advice
          }
          this.loading = true
          if (this.isUpdate) {
            updatePatrolItem(this.form.id, params)
              .then(res => {
                this.$message.success(res.message)
                this.$emit('ok')
                this.closeDrawer()
              })
              .finally(() => {
                this.loading = false
              })
          } else {
            params.name = this.form.name
            params.source_type = this.form.source_type
            params.method = this.form.method
            params.condition = this.form.condition
            params.value = this.form.value
            params.recommended_value = this.form.recommended_value
            createPatrolItem(params)
              .then(res => {
                this.$message.success(res.message)
                this.$emit('ok')
                this.closeDrawer()
              })
              .finally(() => {
                this.loading = false
              })
          }
        }
      })
    }
  }
}
</script>
