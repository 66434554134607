<template>
  <div>
    <a-card :bordered="false" style="margin-bottom: 16px">
      <list-layout
        :getTableDataFunc="getTableDataFunc"
        :columnDefaultSelected="columnDefaultSelected"
        :columnsAll="columnsAll"
        :toolsList="['setting', 'reload', 'leftTool', 'search']"
        :refresh="refresh"
        :defaultSelected="tableSelectData"
        @tableSelectData="(v) => tableSelectData = v"
      >
        <!-- 工具栏 -->
        <template #leftTool>
          <create-button
            @click="$refs.inputDrawer.show()"
          >
          </create-button>
        </template>
        <!-- 表格自定义列 -->
        <template #name="{ slotProps }">
          <a-tooltip placement="topLeft" :title="slotProps.text">
            {{ slotProps.text }}
          </a-tooltip>
        </template>
        <template #condition="{ slotProps }">
          {{ $t(`compare_condition.${slotProps.text}`) }}
        </template>
        <template #method="{ slotProps }">
          {{ $t(`patrol_item_method.${slotProps.text}`) }}
        </template>
        <template #priority="{ slotProps }">
          <a-tag :color="getColor(slotProps.text)">
            {{$t(`patrol_item_priority.${slotProps.text}`)}}
          </a-tag>
        </template>
        <template #sourceType="{ slotProps }">
          {{ $t(`source_type.${slotProps.text}`) }}
        </template>
        <template #description="{ slotProps }">
          <a-tooltip v-if="slotProps.text && slotProps.text !== 'NULL'" placement="topLeft" :title="slotProps.text">
            {{ slotProps.text }}
          </a-tooltip>
          <span v-else>-</span>
        </template>
        <template #advice="{ slotProps }">
          <a-tooltip v-if="slotProps.text && slotProps.text !== 'NULL'" placement="topLeft" :title="slotProps.text">
            {{ slotProps.text }}
          </a-tooltip>
          <span v-else>-</span>
        </template>
        <template #operation="{ slotProps }">
          <edit-button
            @click="$refs.inputDrawer.show(slotProps.record.id)"
          ></edit-button>
          <delete-button
            @confirm="confirm(slotProps.record.id)"
          ></delete-button>
        </template>
      </list-layout>
    </a-card>

    <input-drawer ref="inputDrawer" @ok="confirm"></input-drawer>
  </div>
</template>

<script>
import { getPatrolItemList, deletePatrolItem } from '@/api/patrol-item.js'
import InputDrawer from './modules/InputDrawer'
import ListLayout from '@/components/ListLayout'
import DeleteButton from '@/components/button/DeleteButton'
import EditButton from '@/components/button/EditButton'
import CreateButton from '@/components/button/CreateButton'

export default {
  name: 'PatrolStrategyList',
  components: {
    InputDrawer,
    ListLayout,
    DeleteButton,
    EditButton,
    CreateButton
  },
  data () {
    return {
      getTableDataFunc: getPatrolItemList,
      columnsAll: [
        {
          dataIndex: 'name',
          title: '名称',
          fixed: 'left',
          width: 200,
          ellipsis: true,
          scopedSlots: {
            customRender: 'name'
          }
        },
        {
          dataIndex: 'description',
          title: '描述',
          width: 200,
          ellipsis: true,
          scopedSlots: {
            customRender: 'description'
          }
        },
        {
          dataIndex: 'priority',
          title: '优先级',
          width: 90,
          scopedSlots: {
            customRender: 'priority'
          }
        },
        {
          dataIndex: 'source_type',
          title: '资源类型',
          width: 140,
          scopedSlots: {
            customRender: 'sourceType'
          }
        },
        {
          dataIndex: 'method',
          title: '获取方式',
          width: 120,
          scopedSlots: {
            customRender: 'method'
          }
        },
        {
          dataIndex: 'value',
          title: '监控项',
          width: 340,
          ellipsis: true,
          scopedSlots: {
            customRender: 'value'
          }
        },
        {
          dataIndex: 'condition',
          title: '判断条件',
          width: 120,
          scopedSlots: {
            customRender: 'condition'
          }
        },
        {
          dataIndex: 'recommended_value',
          title: '建议值',
          width: 120,
          scopedSlots: {
            customRender: 'recommendedValue'
          }
        },
        {
          dataIndex: 'advice',
          title: '建议',
          width: 200,
          scopedSlots: {
            customRender: 'advice'
          }
        }
      ],
      columnDefaultSelected: [
        'name',
        'priority',
        'source_type',
        'method',
        'condition',
        'value',
        'recommended_value'
      ],
      refresh: false,
      tableSelectData: []
    }
  },
  methods: {
    async confirm (id = '') {
      if (id) {
        await deletePatrolItem(id).then(res => {
          this.$message.success(res.message)
        })
        this.tableSelectData.splice(this.tableSelectData.findIndex(item => item.key === id), 1)
      }
      this.refresh = !this.refresh
    },
    getColor (v) {
      if (v === 'high') return 'red'
      if (v === 'medium') return 'orange'
      return 'blue'
    }
  }
}
</script>
