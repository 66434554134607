import request from '@/utils/request'

const urlPrefix = '/patrol-items'

export function getPatrolItem (id) {
  return request.get(`${urlPrefix}/${id}`)
}

export function createPatrolItem (data) {
  return request.post(urlPrefix, data)
}

export function getPatrolItemList (params) {
  return request.get(urlPrefix, { params })
}

export function updatePatrolItem (id, data) {
  return request.put(`${urlPrefix}/${id}`, data)
}

export function deletePatrolItem (id) {
  return request.delete(`${urlPrefix}/${id}`)
}
